import React, { useState, useEffect, useCallback } from "react";
import {
  Shield,
  Eye,
  RefreshCw,
  Check,
  AlertCircle,
  Copy,
  ChevronDown,
  ChevronUp,
  BarChart2,
  Clock,
  Save,
  Download,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const DataMasking = () => {
  const [inputData, setInputData] = useState("");
  const [maskedData, setMaskedData] = useState("");
  const [maskingRules, setMaskingRules] = useState({
    names: true,
    emails: true,
    phoneNumbers: true,
    addresses: true,
    creditCards: true,
    socialSecurityNumbers: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [copied, setCopied] = useState(false);
  const [maskingStrength, setMaskingStrength] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const [protectionScore, setProtectionScore] = useState(0);
  const [processingTime, setProcessingTime] = useState(0);
  const [charCount, setCharCount] = useState({ original: 0, masked: 0 });
  const [showDataInsights, setShowDataInsights] = useState(false);

  const handleMaskData = useCallback(() => {
    setIsLoading(true);
    setError("");
    const startTime = performance.now();

    setTimeout(() => {
      try {
        let masked = inputData;
        let maskedChars = 0;

        Object.entries(maskingRules).forEach(([rule, isActive]) => {
          if (isActive) {
            const regex = getRuleRegex(rule);
            masked = masked.replace(regex, (match) => {
              const maskedLength = Math.ceil(
                match.length * (maskingStrength / 100)
              );
              maskedChars += maskedLength;
              return "█".repeat(maskedLength);
            });
          }
        });

        setMaskedData(masked);
        setCharCount({ original: inputData.length, masked: maskedChars });
        setProtectionScore(
          calculateProtectionScore(maskingRules, maskingStrength)
        );
      } catch (err) {
        setError("An error occurred while masking data. Please try again.");
      } finally {
        setIsLoading(false);
        setProcessingTime(Math.round(performance.now() - startTime));
      }
    }, 1000);
  }, [inputData, maskingRules, maskingStrength]);

  useEffect(() => {
    if (inputData) {
      const debounce = setTimeout(() => {
        handleMaskData();
      }, 500);
      return () => clearTimeout(debounce);
    }
  }, [inputData, maskingRules, maskingStrength, handleMaskData]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(maskedData);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleToggle = (key) => {
    setMaskingRules((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleStrengthChange = (e) => {
    setMaskingStrength(Number(e.target.value));
  };

  const getStrengthColor = () => {
    if (maskingStrength < 33) return "rgb(239, 68, 68)";
    if (maskingStrength < 66) return "rgb(245, 158, 11)";
    return "rgb(16, 185, 129)";
  };

  const calculateProtectionScore = (rules, strength) => {
    const activeRules = Object.values(rules).filter(Boolean).length;
    return Math.round(
      (activeRules / Object.keys(rules).length + strength / 100) * 50
    );
  };

  const getRuleRegex = (rule) => {
    switch (rule) {
      case "names":
        return /\b[A-Z][a-z]+ [A-Z][a-z]+\b/g;
      case "emails":
        return /\S+@\S+\.\S+/g;
      case "phoneNumbers":
        return /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g;
      case "addresses":
        return /\d+ [A-Za-z ]+ St[.,] [A-Za-z]+, [A-Z]{2} \d{5}/g;
      case "creditCards":
        return /\b(?:\d{4}[-\s]?){3}\d{4}\b/g;
      case "socialSecurityNumbers":
        return /\b\d{3}-\d{2}-\d{4}\b/g;
      default:
        return /./g;
    }
  };

  return (
    <div className="mx-auto px-4 py-8 space-y-8 font-sans bg-gradient-to-br from-gray-50 to-blue-50 dark:from-gray-900 dark:to-blue-900 min-h-screen">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold mb-8 text-gray-800 dark:text-white flex items-center"
      >
        <Shield className="mr-4 text-blue-500" size={36} />
        Advanced Data Masking
      </motion.h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-xl transition-shadow duration-300"
        >
          <div className="p-6 space-y-6">
            <div className="relative">
              <textarea
                id="input-data"
                rows={8}
                className="w-full font-sans p-3 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition duration-200 peer pt-8"
                value={inputData}
                onChange={(e) => setInputData(e.target.value)}
                placeholder=" "
              />
              <label
                htmlFor="input-data"
                className="absolute left-3 top-3 text-gray-600 dark:text-gray-400 text-sm transition-all duration-200 peer-placeholder-shown:top-5 peer-placeholder-shown:text-base peer-focus:top-3 peer-focus:text-sm"
              >
                Enter sensitive data
              </label>
            </div>

            <div>
              <button
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="flex items-center text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                {showAdvanced ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
                <span className="ml-2">Advanced Options</span>
              </button>
              <AnimatePresence>
                {showAdvanced && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mt-4 space-y-3"
                  >
                    {Object.entries(maskingRules).map(([key, value]) => (
                      <label
                        key={key}
                        className="flex items-center justify-between"
                      >
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </span>
                        <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                          <input
                            type="checkbox"
                            name={key}
                            id={key}
                            checked={value}
                            onChange={() => handleToggle(key)}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer transition-transform duration-200 ease-in-out"
                            style={{
                              transform: value
                                ? "translateX(100%)"
                                : "translateX(0)",
                              borderColor: value ? "#4299e1" : "#d1d5db",
                            }}
                          />
                          <label
                            htmlFor={key}
                            className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer transition-colors duration-200 ease-in-out ${
                              value ? "bg-blue-500" : "bg-gray-300"
                            }`}
                          ></label>
                        </div>
                      </label>
                    ))}
                    <div className="mt-6">
                      <label
                        htmlFor="masking-strength"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                      >
                        Masking Strength
                      </label>
                      <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                              Weak
                            </span>
                          </div>
                          <div className="text-right">
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                              Strong
                            </span>
                          </div>
                        </div>
                        <div className="relative">
                          <input
                            type="range"
                            id="masking-strength"
                            min="0"
                            max="100"
                            value={maskingStrength}
                            onChange={handleStrengthChange}
                            onMouseDown={() => setIsDragging(true)}
                            onMouseUp={() => setIsDragging(false)}
                            onTouchStart={() => setIsDragging(true)}
                            onTouchEnd={() => setIsDragging(false)}
                            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            style={{
                              background: `linear-gradient(to right, ${getStrengthColor()} 0%, ${getStrengthColor()} ${maskingStrength}%, #e5e7eb ${maskingStrength}%, #e5e7eb 100%)`,
                            }}
                          />
                          <div
                            className="absolute -top-8 left-1/2 transform -translate-x-1/2"
                            style={{ left: `${maskingStrength}%` }}
                          >
                            <motion.div
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{
                                scale: isDragging ? 1.1 : 1,
                                opacity: 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 300,
                                damping: 20,
                              }}
                              className="bg-blue-500 text-white px-2 py-1 rounded-md text-xs font-bold"
                            >
                              {maskingStrength}%
                            </motion.div>
                          </div>
                        </div>
                        <div className="mt-2 text-center">
                          <motion.span
                            key={maskingStrength}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="text-sm font-medium"
                            style={{ color: getStrengthColor() }}
                          >
                            {maskingStrength < 33
                              ? "Low Protection"
                              : maskingStrength < 66
                              ? "Moderate Protection"
                              : "High Protection"}
                          </motion.span>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-xl transition-shadow duration-300"
        >
          <div className="p-6 space-y-6">
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white flex items-center justify-between">
              <span className="flex items-center">
                <Eye size={24} className="mr-2 text-green-500" />
                Masked Result
              </span>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={copyToClipboard}
                className="text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                {copied ? <Check size={20} /> : <Copy size={20} />}
              </motion.button>
            </h2>
            {isLoading ? (
              <div className="flex justify-center items-center h-40">
                <RefreshCw size={24} className="animate-spin text-blue-500" />
              </div>
            ) : maskedData ? (
              <div className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg max-h-80 overflow-y-auto custom-scrollbar">
                <pre className="text-sm text-gray-800 dark:text-gray-200 whitespace-pre-wrap">
                  {maskedData}
                </pre>
              </div>
            ) : null}
            {error && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg flex items-center"
              >
                <AlertCircle size={20} className="mr-2" />
                {error}
              </motion.div>
            )}

            <div className="mt-4">
              <button
                onClick={() => setShowDataInsights(!showDataInsights)}
                className="flex items-center text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                {showDataInsights ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
                <span className="ml-2">Data Insights</span>
              </button>
              <AnimatePresence>
                {showDataInsights && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mt-4 space-y-4"
                  >
                    <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold text-blue-800 dark:text-blue-200 mb-2">
                        Masking Statistics
                      </h3>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-sm text-blue-600 dark:text-blue-300">
                            Protection Score
                          </p>
                          <p className="text-2xl font-bold text-blue-800 dark:text-blue-100">
                            {protectionScore}%
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-blue-600 dark:text-blue-300">
                            Processing Time
                          </p>
                          <p className="text-2xl font-bold text-blue-800 dark:text-blue-100">
                            {processingTime}ms
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-blue-600 dark:text-blue-300">
                            Original Characters
                          </p>
                          <p className="text-2xl font-bold text-blue-800 dark:text-blue-100">
                            {charCount.original}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-blue-600 dark:text-blue-300">
                            Masked Characters
                          </p>
                          <p className="text-2xl font-bold text-blue-800 dark:text-blue-100">
                            {charCount.masked}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-green-100 dark:bg-green-900 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold text-green-800 dark:text-green-200 mb-2">
                        Active Rules
                      </h3>
                      <ul className="list-disc list-inside">
                        {Object.entries(maskingRules).map(
                          ([rule, isActive]) =>
                            isActive && (
                              <li
                                key={rule}
                                className="text-green-600 dark:text-green-300"
                              >
                                {rule
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, (str) => str.toUpperCase())}
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 hover:shadow-xl transition-shadow duration-300"
      >
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">
            Quick Actions
          </h2>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                /* Implement save preset functionality */
              }}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              <Save size={20} className="mr-2" />
              Save Preset
            </button>
            <button
              onClick={() => {
                /* Implement load preset functionality */
              }}
              className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200"
            >
              <Download size={20} className="mr-2" />
              Load Preset
            </button>
            <button
              onClick={() => {
                /* Implement quick mask functionality */
              }}
              className="flex items-center px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition-colors duration-200"
            >
              <Eye size={20} className="mr-2" />
              Quick Mask
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default DataMasking;
