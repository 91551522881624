import React, { useState } from 'react';
import { Send, Calendar, Mail, MessageSquare, RefreshCw } from 'react-feather';
import { motion, AnimatePresence } from 'framer-motion';

const AssistantV2 = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isConversationStarted, setIsConversationStarted] = useState(false);

  const predefinedPrompts = [
    { icon: '📅', text: 'Could you provide details on upcoming team meetings scheduled for next week?' },
    { icon: '📈', text: "I'm interested in trends in website traffic over the last month. Could you provide a summary?" },
    { icon: '🎉', text: 'I need access to the latest version of the marketing presentation. Where can I find it?' },
  ];

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { type: 'user', content: input }]);
      setInput('');
      setIsConversationStarted(true);
      // Simulate AI response
      setTimeout(() => {
        setMessages(msgs => [...msgs, { 
          type: 'assistant', 
          content: 'Here is a simulated response to your question. In a real implementation, this would be the AI\'s answer.',
          sources: ['Your calendar', 'Your inbox', 'Your previous messages']
        }]);
      }, 1000);
    }
  };

  const handlePromptClick = (promptText) => {
    setInput(promptText);
    handleSend();
  };

  const MessageBubble = ({ message, isLast }) => (
    <div className={`flex ${message.type === 'user' ? 'flex-col justify-end items-end' : 'justify-start flex flex-col'} mb-4`}>
      {message.type === 'user' && (
        <div className="w-8 h-8 mb-3 rounded-full bg-blue-200 flex items-center justify-center mr-3">
          <span className="text-sm font-semibold">U</span>
        </div>
      )}
        {message.type === 'assistant' && (
          <div className="flex items-center mb-4">
            <div className="bg-blue-500 rounded-lg p-2 mr-3">
              <span className="font-bold text-lg bg-clip-text text-white">AI</span>
            </div>
            <span className="font-medium text-blue-500 text-xl">Avahi AI</span>
          </div>
        )}
      <div className={`max-w-3xl rounded-2xl p-6 ${
        message.type === 'user' 
          ? 'bg-[#EBF2FF] dark:bg-blue-900/30 text-[#252525] dark:text-blue-200' 
          : 'bg-gradient-to-br from-purple-500 to-indigo-600 text-white'
      }`}>
      
        <p className="text-xl font-poppins">{message.content}</p>
      </div>
      {message.type === 'assistant' && isLast && message.sources && (
        <div className="mt-4 ml-6">
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">3 relevant sources found</p>
          <div className="space-y-2">
            {message.sources.map((source, idx) => (
              <div key={idx} className="flex items-center">
                {idx === 0 && <Calendar size={18} className="mr-3 text-gray-500" />}
                {idx === 1 && <Mail size={18} className="mr-3 text-gray-500" />}
                {idx === 2 && <MessageSquare size={18} className="mr-3 text-gray-500" />}
                <span className="text-base text-gray-700 dark:text-gray-300">{source}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex font-poppins flex-col h-full bg-gray-50 dark:bg-gray-900 p-6 overflow-hidden">
      <AnimatePresence>
        {!isConversationStarted && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="flex-grow flex flex-col justify-center items-center text-center"
          >
            <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
              Hi there, John!
            </h1>
            <h2 className="text-4xl font-semibold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
              What would you like to know?
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
              Use one of the common prompts below or write your own.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 w-full max-w-4xl">
              {predefinedPrompts.map((prompt, index) => (
                <button
                  key={index}
                  className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow text-left"
                  onClick={() => handlePromptClick(prompt.text)}
                >
                  <span className="text-4xl mb-4 block">{prompt.icon}</span>
                  <span className="text-lg text-gray-700 dark:text-gray-300">{prompt.text}</span>
                </button>
              ))}
            </div>
            <button className="flex items-center text-blue-500 hover:text-blue-600 text-lg">
              <RefreshCw size={20} className="mr-2" />
              Refresh prompts
            </button>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isConversationStarted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex-grow overflow-auto space-y-6 mb-6"
          >
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <MessageBubble message={message} isLast={index === messages.length - 1} />
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="mt-auto">
        {isConversationStarted && (
          <div className="bg-gray-200 dark:bg-gray-700 p-3 rounded-lg flex items-center mb-4">
            <Calendar size={20} className="text-gray-500 mr-3" />
            <span className="text-base text-gray-700 dark:text-gray-300">Suggested: Send me a reminder before for Thursday's meeting</span>
          </div>
        )}
        <div className="relative">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder={isConversationStarted ? "Ask a follow-up question..." : "Ask whatever you want..."}
            className="w-full p-5 pr-24 rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-xl focus:outline-none focus:border-blue-500"
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <button
            onClick={handleSend}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
          >
            <Send size={28} />
          </button>
          <div className="absolute right-16 top-1/2 transform -translate-y-1/2 text-gray-400">
            <span className="text-sm">{input.length}/1000</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantV2;