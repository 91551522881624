


import React from 'react';

const Tick = ({className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_401)">
    <rect width="24" height="24" rx="12" fill="#9747FF"/>
    <path d="M17.7958 9.18239L10.4134 16.5648C10.3491 16.6293 10.2727 16.6805 10.1885 16.7155C10.1044 16.7504 10.0142 16.7684 9.92312 16.7684C9.83202 16.7684 9.74183 16.7504 9.6577 16.7155C9.57358 16.6805 9.49717 16.6293 9.43288 16.5648L6.20306 13.335C6.13868 13.2706 6.08762 13.1942 6.05277 13.1101C6.01793 13.026 6 12.9358 6 12.8448C6 12.7537 6.01793 12.6636 6.05277 12.5794C6.08762 12.4953 6.13868 12.4189 6.20306 12.3545C6.26744 12.2901 6.34387 12.2391 6.42799 12.2042C6.5121 12.1694 6.60226 12.1515 6.6933 12.1515C6.78435 12.1515 6.8745 12.1694 6.95862 12.2042C7.04273 12.2391 7.11916 12.2901 7.18354 12.3545L9.92369 15.0947L16.8165 8.20306C16.9465 8.07304 17.1228 8 17.3067 8C17.4906 8 17.6669 8.07304 17.7969 8.20306C17.927 8.33308 18 8.50943 18 8.6933C18 8.87718 17.927 9.05352 17.7969 9.18354L17.7958 9.18239Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_1_401">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
);

export default Tick;