import React from "react";
import {
  BarChart2,
  Heart,
  PlusCircle,
  ThumbsDown,
  ThumbsUp,
} from "react-feather";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  LabelList,
  Line,
  LineChart,
  ReferenceLine,
} from "recharts";
import Lightbulb from "../assets/icons/Lightbulb";
import Info from "../assets/icons/Info";
import Tick from "../assets/icons/Tick";
import useAuthStore from "../stores/authStore";

const DashboardAlertBox = ({
  text,
  borderColor,
  backgroundColor,
  textColor,
  count,
  borderWidth,
}) => {
  return (
    <div
      className={`px-3 flex border-[${borderWidth}px] flex-row py-[6px] gap-2 items-center rounded-full`}
      style={{ backgroundColor: backgroundColor, borderColor: borderColor }}
    >
      <span className="text-sm font-semibold" style={{ color: textColor }}>
        {text}
      </span>
      {count && (
        <div
          className="rounded-full flex items-center justify-center px-2 py-[2px]"
          style={{ backgroundColor: borderColor }}
        >
          <span className="text-sm" style={{ color: textColor }}>
            {count}
          </span>
        </div>
      )}
    </div>
  );
};

const Dashboard = () => {
  const dummyChartData = [
    { name: "WK 14", value: 1300 },
    { name: "WK 15", value: 1100 },
    { name: "WK 16", value: 1400 },
    { name: "WK 17", value: 1200 },
    { name: "WK 18", value: 1600 },
  ];

  const { user } = useAuthStore();

  const manufacturerData = [
    { name: "GBC", value: 8.2, percentage: 29.5, totalWidth: 29.5 },
    { name: "Other", value: 4.5, percentage: 16.2, totalWidth: 55.7 },
    { name: "Martin-Yale", value: 4.2, percentage: 14.9, totalWidth: 70.6 },
    {
      name: "Sum of 42 others",
      value: 11.0,
      percentage: 39.4,
      totalWidth: 100,
      isGrey: true,
    },
  ];

  const insightQuestions = [
    { id: 1, question: "Which Sub-Category had very high Branch Revenue?" },
    { id: 2, question: "Which Sub-Category decreased the most?" },
    { id: 2, question: "Which Ship Mode had high Branch Revenue?" },
  ];

  const categoryData = [
    {
      name: "Office Supplies",
      value: 17.6,
      percentage: 63.2,
      totalWidth: 63.2,
    },
    {
      name: "Sum of 2 others",
      value: 10.3,
      percentage: 36.8,
      totalWidth: 100,
      isGrey: true,
    },
  ];

  return (
    <div className="p-6 bg-[#FCFCFC] px-12">
      <h1 className="text-3xl text-black dark:text-black font-semibold mb-2">
        Hi {user?.username || 'User'}! Here are today's smart insights.
      </h1>

      <div className="mb-4">
        <p className="text-sm text-[#BDB9C3] font-medium mt-10">
          Last updated 5 minutes ago
        </p>
        <div className="flex items-start mt-2">
          <div className="mt-[0.05rem]">
            <Lightbulb />
          </div>

          <div>
            <p className="ml-1 mb-2 text-black">
              <strong>
                <u>Appliance Sales</u>
              </strong>{" "}
              is seeing an unusual spike, while{" "}
              <strong>
                <u>Branch Revenue</u>
              </strong>{" "}
              and{" "}
              <strong>
                <u>Campaign ROI</u>
              </strong>{" "}
              are steadily increasing. Of the 12 metrics you are following, 2 is
              unusual.
            </p>

            <div className="flex -ml-4 mt-4 mb-6">
              <p className="mr-4">Was this helpful?</p>
              <button className="mr-2">
                <ThumbsUp size={18} />
              </button>
              <button>
                <ThumbsDown size={18} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-2 mb-10">
        <div className="px-3 flex flex-row py-[6px] gap-2 items-center border-[#FDBAD1] bg-[#FEE6EE] border-[1.5px] rounded-full">
          <span className=" text-black font-medium text-sm">Unusual</span>
          <div className="bg-[#FDBAD1] rounded-full flex items-center justify-center px-2 py-[2px]">
            <span className="text-sm text-black">2</span>
          </div>
        </div>

        <div className="px-3 py-1 flex flex-row gap-3 items-center border-[#D8E6FF] border-[1.5px] bg-[#EBF2FF] rounded-full ">
          <span className=" text-black font-medium text-sm">Normal</span>
          <div className="bg-[#AECCFF] rounded-full flex items-center justify-center px-2 py-[1px]">
            <span className="text-sm text-black">1</span>
          </div>
        </div>
      </div>

      <div className="mb-4 flex justify-between">
        <div>
          <ul className="flex space-x-4 border-b">
            <li className="pb-2 border-b-2 font-bold border-black text-black">
              Following
            </li>
            <li className="pb-2 font-medium text-gray-500">For You</li>
            <li className="pb-2 font-medium text-gray-500">All Metrics</li>
          </ul>
        </div>
        <button className="rounded-lg flex py-2 flex-row gap-2 items-center justify-center px-3 bg-[#377FFF] text-white font-medium text-base">
          Create new metric
          <PlusCircle />
        </button>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
        <MetricCard
          title="Appliance Sales"
          subtitle="Week to date, Los Angeles"
          value="1,675 units"
          change="+356 units (+27% compared to previous period)"
          isUnusual={true}
          chartData={dummyChartData}
          color="green"
          hasHeartIcon={true}
          insight={
            <>
              The week to date{" "}
              <strong>
                <span className="text-[#2D2D2D]">Appliance Sales</span>
              </strong>{" "}
              has seen an{" "}
              <span className="text-[#159722] font-semibold">
                unusual increase{" "}
              </span>{" "}
              of{" "}
              <span className="text-[#159722] font-semibold">
                {" "}
                +356 units (+27%)
              </span>{" "}
              over the last week and is now 17% above the{" "}
              <span className="font-semibold text-[#377FFF]">normal range</span>
              .
            </>
          }
          insightIcon={true}
        />
        <MetricCard
          title="Branch Revenue"
          subtitle="Quarter to date, London"
          value="$6.7M"
          change="+$0.3M (+7% QTD)"
          isUnusual={true}
          chartData={dummyChartData}
          color="red"
          hasHeartIcon={true}
          insight={
            <>
              The quarter to date{" "}
              <strong>
                <span className="text-[#2D2D2D]">Branch Revenue</span>
              </strong>{" "}
              had an unusual increase of{" "}
              <span className="text-[#ED4E4E] font-semibold"> +1.2% </span>{" "}
              oover the equivalent quarter to date value a year ago, mainly
              attributed to{" "}
              <span className="font-semibold text-[#2D2D2D]">
                Home Appliance.
              </span>
              .
            </>
          }
        />
        <MetricCard
          title="Campaign ROI"
          subtitle="Monthly, Fremont"
          value="379%"
          change="+1.1% since last month"
          isNormal={true}
          chartData={dummyChartData}
          color="blue"
          hasHeartIcon={true}
          insight={
            <>
              The monthly{" "}
              <strong>
                <span className="text-[#2D2D2D] font-semibold">
                  Campaign ROI
                </span>
              </strong>{" "}
              has been increasing at a rate of 1 percentage point per month for
              the past 6 months, in line with change in rate of increase on{" "}
              <span className="font-semibold text-[#2D2D2D]">Social Media</span>{" "}
              campaigns.
            </>
          }
          insightIcon={true}
        />
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Additional Insights</h2>

        <div className="flex w-full flex-wrap gap-4 mb-5">
          {insightQuestions.map((item) => {
            return (
              <div className="bg-[#FFFFFF] inline-flex border-[1.5px] border-[#F1EBF8] items-center px-5 py-3 rounded-3xl">
                <h3 className="font-medium text-[#377FFF] text-base">
                  {item.question}
                </h3>
              </div>
            );
          })}
        </div>

        <div className="bg-[#EBF2FF] inline-flex  items-center px-5 py-3 rounded-3xl mb-5">
          <h3 className="text-[#918B9C] font-medium text-base">
            You asked |{"   "}
            <span className="text-[#377FFF] font-semibold text-base">
              {" "}
              Which Manufacturer had very high Branch Revenue?
            </span>
          </h3>
        </div>

        <InsightCard
          question="Which Manufacturer had very high Branch Revenue?"
          insight={
            <>
              <strong>
                <span className="text-[#2D2D2D] font-semibold">
                  Just 3 members in Manufacturer make up the majority
                </span>
              </strong>{" "}
              (60.6%) of Branch Revenue:{" "}
              <span className="text-[#2D2D2D] font-semibold">GBC </span> with
              $8.2k (29.5%) Other with $4.5k (16.2%), and{" "}
              <span className="text-[#2D2D2D] font-semibold">Martin-Yale</span>{" "}
              with $4.2k (14.9%). 42 others contributed $11.0k.
            </>
          }
          data={manufacturerData}
        />

        <div className="bg-[#EBF2FF] inline-flex  items-center px-5 py-3 rounded-3xl mb-5">
          <h3 className="text-[#918B9C] font-medium text-base">
            Top insight about this change |{"   "}
            <span className="text-[#377FFF] font-semibold text-base">
              {" "}
              Which Category had very high Branch Revenue
            </span>
          </h3>
        </div>

        <InsightCard
          question="Which Category had very high Branch Revenue?"
          insight={
            <>
              <strong>
                <span className="text-[#2D2D2D] font-semibold">
                  Office Supplies
                </span>
              </strong>{" "}
              ($17.6k){" "}
              <span className="text-[#2D2D2D] font-semibold">
                contributed the majority{" "}
              </span>{" "}
              (63.2%) of Total Sales. 2 others contributed $10.3k (36.8%).
            </>
          }
          data={categoryData}
        />
      </div>
    </div>
  );
};

const InsightCard = ({ question, insight, data }) => {
  const primaryColor = "#8b5cf6"; // Purple
  const barColor = "#f97316"; // Orange
  const greyBarColor = "#E5E7EB"; // Grey for the last bar

  return (
    <div className="bg-white p-6 border-2 border-[#F1EBF8] rounded-[36px] mb-6">
      <div className=" p-4">
        <div className="flex items-start">
          <div className="mt-[2px]">
            <Lightbulb />
          </div>

          <p className="text-black font-light ml-1 leading-6 text-lg">
            {insight}
          </p>
        </div>
      </div>
      <div className="overflow-x-auto">
        <div style={{ minWidth: "600px" }}>
          <ResponsiveContainer width="100%" height={data.length * 50 + 20}>
            <BarChart
              data={data}
              layout="vertical"
              barSize={20}
              margin={{ top: 0, right: 50, bottom: 0, left: 50 }}
            >
              <XAxis type="number" hide />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#6b7280", fontSize: 14 }}
              />
              <Bar dataKey="value" fill={barColor} radius={[0, 10, 10, 0]}>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.isGrey ? greyBarColor : barColor}
                    strokeWidth={0}
                  />
                ))}
                <ReferenceLine y={10} label="" stroke="black" strokeWidth={2} />

                <LabelList
                  dataKey="value"
                  position="right"
                  style={{ fill: "#6b7280", fontSize: 14 }}
                  content={(props) => {
                    const { x, y, value, width, payload } = props;
                    return (
                      <text
                        x={x + width + 5}
                        y={y + 13}
                        fill="#f97316"
                        fontSize={14}
                      >
                        ${value}k ({payload?.percentage}%)
                      </text>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

const MetricCard = ({
  title,
  subtitle,
  value,
  change,
  isUnusual,
  isNormal,
  chartData,
  color,
  insight,
  insightIcon,
  tickIcon,
  hasHeartIcon,
}) => {
  const alertBoxColor = () => {
    if (color === "green") {
      return "#D5F7D8";
    } else if (color === "red") {
      return "#FFD7D7";
    } else if (color === "blue") {
      return "#EBF2FF";
    }
  };

  const alertBoxTextColor = () => {
    if (color === "green") {
      return "#159722";
    } else if (color === "red") {
      return "#ED4E4E";
    } else if (color === "blue") {
      return "#2E7CFE";
    }
  };

  return (
    <div className="bg-white p-6 rounded-3xl border-[#F1EBF8] border-[1.5px] shadow">
      <div className="flex justify-between items-center mb-2">
        <div>
          <h2 className="font-bold text-xl">{title}</h2>
          <p className="text-base font-medium text-[#918B9C]">{subtitle}</p>
        </div>
        <div className="flex items-center">
          {hasHeartIcon && (
            <Heart fill="#9747FF" className="text-[#9747FF] mr-2" />
          )}
          {tickIcon && <Tick className="mr-2" />}
          <DashboardAlertBox
            text={isUnusual ? "Unusual" : "Normal"}
            backgroundColor={alertBoxColor()}
            textColor={alertBoxTextColor()}
            borderColor={alertBoxTextColor()}
            borderWidth={color === "blue" ? 0 : 1.5}
          />
        </div>
      </div>
      <div className="mt-6">
        <h3
          className={`font-bold mb-2 text-[2rem] ${
            color === "green"
              ? "text-[#159722]"
              : color === "red"
              ? "text-[#ED4E4E]"
              : "text-[#377FFF]"
          }`}
        >
          {value}
        </h3>
        <p
          className={`text-sm font-semibold ${
            color === "green"
              ? "text-[#159722]"
              : color === "red"
              ? "text-[#ED4E4E]"
              : "text-[#377FFF]"
          }`}
        >
          {change}
        </p>
      </div>
      <div className="mt-4 h-48">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Line
              type="monotone"
              dataKey="value"
              stroke={
                color === "green"
                  ? "#10B981"
                  : color === "red"
                  ? "#EF4444"
                  : "#377FFF"
              }
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 flex items-start">
        {insightIcon && (
          <BarChart2 className="text-black font-light mr-2 flex-shrink-0 mt-1" />
        )}
        <div className="text-sm font-light">{insight}</div>
      </div>
    </div>
  );
};

export default Dashboard;
