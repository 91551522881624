// src/routes.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import ChatBot from './components/Chat/ChatBot';
import MeetingSummarizer from './components/MeetingSummarizer';
import OpenAIToBedrock from './components/OpenAIToBedrock';
import DataExtractor from './components/DataExtractor';
import EntityResolution from './components/EntityResolution';
import MedicalScribing from './components/MedicalScribing';
import DataMasking from './components/DataMasking';
import ImageGeneration from './components/ImageGeneration';
import PDFViewer from './components/PdfViewer';

import Dashboard from './components/Dashboard';
import DashboardOld from './components/DashboardOld';
import Inbox from './components/Inbox';
import Reports from './components/Reports';
import AssistantV2 from './components/AssistantV2';
import CSVQuerying from './components/CsvQuerying';
import NL2SQL from './components/NL2SQL';

const routes = [
  {
    path: '/login',
    element: <Login />,
    public: true
  },
  {
    path: '/register',
    element: <Register />,
    public: true
  },
  {
    path: '/chat',
    element: <ChatBot />,
    protected: true,
    roles: ['Customer', 'HR Employee', 'IT Employee', 'Sales Employee', 'HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },
  {
    path: '/nl2sql',
    element: <NL2SQL />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/meeting-summarizer',
    element: <MeetingSummarizer />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin']
  },
  {
    path: '/openai-to-bedrock',
    element: <OpenAIToBedrock />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/data-extractor',
    element: <DataExtractor />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/medical-scribing',
    element: <MedicalScribing />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin']
  },
  {
    path: '/data-masking',
    element: <DataMasking />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/entity-resolution',
    element: <EntityResolution />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/image-generation',
    element: <ImageGeneration />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/pdf-viewer',
    element: <PDFViewer />,
    protected: true,
    roles: ['Customer', 'HR Employee', 'IT Employee', 'Sales Employee', 'HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },
  {
    path: '/tax-processing',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/contract-management',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/smart-search',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/claims-ai',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/prior-authorization',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/csv-querying',
    element: <CSVQuerying />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin']
  },
  {
    path: '/dashboard-v2',
    element: <DashboardOld />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },
  {
    path: '/billing-ai',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/inbox',
    element: <Inbox/>,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
    public: true
  },
  {
    path: '/reports',
    element: <Reports />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },
  {
    path: '/coding-ai',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/settlement-processing',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '/assistant-v2',
    element: <AssistantV2 />,
    protected: true,
    roles: ['Customer', 'HR Employee', 'IT Employee', 'Sales Employee', 'HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin']
  },

  {
    path: '/lending-copilot',
    element: <Navigate to="/login" replace />,
    public: true
  },
  {
    path: '*',
    element: <Navigate to="/login" replace />,
    public: true
  }
];

export default routes;