

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import {
  Database,
  Play,
  Copy,
  Check,
  ChevronDown,
  ChevronUp,
  Clock,
  AlertCircle,
  Search,
  Code,
  Clipboard,
  CheckSquare,
  RefreshCcw,
  Upload,
  Send,
  X,
  FolderPlus,
  FileText,
  ArrowUp,
} from "react-feather";
import Select from "react-select";

import { motion, AnimatePresence } from "framer-motion";
import { useTransition, animated, config } from "@react-spring/web";
import useNL2SQLStore from "../stores/useNL2SQLStore.js";
import { toast } from "react-toastify";
import useAuthStore from "../stores/authStore.js";

const formatText = (text) => {
  const lines = text.split("\n");
  return lines.map((line, index) => {
    const trimmedLine = line?.trim();
    if (trimmedLine.startsWith("Answer:") || trimmedLine.endsWith(":")) {
      return (
        <p key={index} className="font-poppins mb-2">
          {trimmedLine}
        </p>
      );
    } else if (/^\d+\./.test(trimmedLine)) {
      return (
        <p key={index} className="font-poppins ml-4 mb-1">
          {trimmedLine}
        </p>
      );
    } else {
      return (
        <p key={index} className="font-poppins mb-1">
          {trimmedLine}
        </p>
      );
    }
  });
};

const ChatBody = ({ messages, loading }) => {
  const messagesEndRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const transitions = useTransition(messages, {
    from: { opacity: 0, transform: "translateY(20px)" },
    enter: (item) => async (next) => {
      await next({ opacity: 1, transform: "translateY(0)" });
    },
    leave: { opacity: 0 },
    trail: 100,
    config: config.gentle,
    keys: (message, index) => index,
  });

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, loading]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        messagesEndRef.current.parentElement;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    };

    const scrollContainer = messagesEndRef.current.parentElement;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderMessageContent = (msg) => {
    return <div className="message-content">{formatText(msg.text)}</div>;
  };

  return (
    <div className="relative flex-1 p-4 overflow-y-auto bg-gray-50 dark:bg-gray-900 scroll-smooth">
      {transitions((style, msg, _, index) => (
        <animated.div
          style={index === messages.length - 1 ? style : {}}
          className={`flex mb-6 ${
            msg.type === "ai" ? "justify-start" : "justify-end"
          }`}
          key={index}
        >
          {msg.type === "ai" && (
            <div className="flex-shrink-0 mr-4">
              <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center shadow-md">
                <img
                  alt="AI Agent"
                  src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                  className="w-6 h-6"
                />
              </div>
            </div>
          )}
          <div
            className={`p-4 rounded-lg max-w-xl shadow-lg ${
              msg.type === "ai"
                ? "bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
                : "bg-[#377FFF] text-white"
            }`}
          >
            {renderMessageContent(msg)}
          </div>
        </animated.div>
      ))}
      {loading && (
        <div className="flex mb-6 justify-start">
          <div className="flex-shrink-0 mr-4">
            <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center shadow-md">
              <img
                alt="AI Agent"
                src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                className="w-6 h-6"
              />
            </div>
          </div>
          <div className="p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-white">
            <div className="flex justify-center items-center space-x-2">
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse"></div>
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse delay-75"></div>
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse delay-150"></div>
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
      {showScrollButton && (
        <button
          className="fixed bottom-10 right-4 bg-[#377FFF] text-white rounded-full p-3 shadow-xl hover:bg-blue-600 transition-all duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
          onClick={scrollToBottom}
        >
          <ChevronDown size={24} />
        </button>
      )}
    </div>
  );
};

const CSVPreviewPlaceholder = () => (
  <div className="flex flex-col items-center justify-center h-full text-center p-8 bg-gray-50 dark:bg-gray-800 rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-600">
    <FileText size={48} className="text-gray-400 dark:text-gray-500 mb-4" />
    <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-2">
      No CSV File Selected
    </h3>
    <p className="text-gray-500 dark:text-gray-400 mb-4">
      Select a CSV file from the dropdown above to preview its contents here.
    </p>
    <div className="animate-bounce">
      <ArrowUp size={24} className="text-blue-500" />
    </div>
  </div>
);


const CSVTable = ({ data, fileName }) => {
  if (!data || data.length === 0) return null;

  const headers = Object.keys(data[0]);

  return (
    <div className="overflow-x-auto">
      <h3 className="text-lg font-semibold mb-2">{fileName}</h3>
      <table className="w-full">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="p-2 text-left text-sm font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {data.slice(0, 5).map((row, index) => (
            <tr
              key={index}
              className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 ease-in-out"
            >
              {headers.map((header) => (
                <td
                  key={`${index}-${header}`}
                  className="p-2 text-sm text-gray-700 dark:text-gray-300"
                >
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Loader = ({ size = 24, color = "#377FFF" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="animate-spin"
  >
    <line x1="12" y1="2" x2="12" y2="6"></line>
    <line x1="12" y1="18" x2="12" y2="22"></line>
    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
    <line x1="2" y1="12" x2="6" y2="12"></line>
    <line x1="18" y1="12" x2="22" y2="12"></line>
    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
  </svg>
);

const MessageBox = ({ type, title, message, onClose }) => {
  const isError = type === "error";
  const bgColor = isError ? "bg-red-100" : "bg-green-100";
  const iconBgColor = isError ? "bg-red-400" : "bg-green-400";
  const textColor = isError ? "text-red-800" : "text-green-800";
  const Icon = isError ? X : Check;

  return (
    <div className={`flex items-center mt-5  p-4 rounded-lg ${bgColor}`}>
      <div
        className={`flex-shrink-0 w-10 h-10 rounded-lg ${iconBgColor} flex items-center justify-center mr-4`}
      >
        <Icon className="text-white" size={20} />
      </div>
      <div className="flex-grow">
        <h3 className={`text-sm font-medium ${textColor}`}>{title}</h3>
        <p className={`mt-1 text-sm ${textColor} opacity-80`}>{message}</p>
      </div>
      {onClose && (
        <button
          onClick={onClose}
          className="ml-auto text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Close</span>
          <X size={20} />
        </button>
      )}
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="animate-pulse w-full h-full">
    {/* Table Header */}
    <div className="mb-4 h-10 bg-gray-200 dark:bg-gray-700 rounded"></div>
    
    {/* Table Rows */}
    {[...Array(10)].map((_, rowIndex) => (
      <div key={rowIndex} className="flex mb-4">
        {[...Array(5)].map((_, colIndex) => (
          <div 
            key={colIndex} 
            className="h-8 bg-gray-200 dark:bg-gray-700 rounded mr-2 flex-grow"
            style={{width: `${Math.floor(Math.random() * 15 + 10)}%`}}
          ></div>
        ))}
      </div>
    ))}

    {/* Query Input Skeleton */}
    <div className="mt-8 h-12 bg-gray-200 dark:bg-gray-700 rounded"></div>
  </div>
);

//TODO: Cleanup all states whenever we move out of this page!

const CsvQuerying = () => {
  const {
    selectedFolder,
    selectedFiles,
    folderOptions,
    fileOptions,
    csvData,
    csvPaths,
    chatHistory,
    isFolderLoading,
    isFileLoading,
    isUploadLoading,
    isQueryLoading,
    isPreviewLoading,
    error,
    folderName,
    fetchFolderOptions,
    fetchFileOptions,
    handleFolderSelect,
    handleFileSelect,
    uploadCSV,
    handleQuery,
    setNewFolderName,
    createFolder,
    resetState,
  } = useNL2SQLStore();

  const { user } = useAuthStore();

  const [localQuery, setLocalQuery] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);

  const companyFolder = user?.companyFolder;

  const TOTAL_CSVS = 4;

  console.log('new folder name',folderName)

  const onDrop = useCallback((acceptedFiles) => {
    setUploadFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false, // Set to true for multiple file uploads
  });

  useEffect(() => {
    fetchFolderOptions(companyFolder);
    return () => {
      resetState();
    };
  }, [fetchFolderOptions, companyFolder, resetState]);

  const folderSelectOptions = folderOptions?.map((option) => ({
    value: option,
    label: option,
  }));

  const fileSelectOptions = fileOptions?.map((option) => ({
    value: option,
    label: option,
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: state.isFocused ? "#377FFF" : "#e2e8f0",
      boxShadow: state.isFocused ? "0 0 0 2px #bfdbfe" : "none",
      "&:hover": {
        borderColor: "#377FFF",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#377FFF"
        : state.isFocused
        ? "#bfdbfe"
        : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--bg-color)",
      borderColor: state.isFocused
        ? "var(--focus-color)"
        : "var(--border-color)",
      boxShadow: state.isFocused ? "0 0 0 1px var(--focus-color)" : "none",
      "&:hover": {
        borderColor: "var(--hover-color)",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--selected-bg)"
        : state.isFocused
        ? "var(--hover-bg)"
        : "var(--bg-color)",
      color: state.isSelected ? "var(--selected-text)" : "var(--text-color)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--bg-color)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--text-color)",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor:
        selectedFiles.length >= TOTAL_CSVS ? "#FED7D7" : "#E6FFFA",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: selectedFiles.length >= TOTAL_CSVS ? "#9B2C2C" : "#234E52",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: selectedFiles.length >= TOTAL_CSVS ? "#9B2C2C" : "#234E52",
      ":hover": {
        backgroundColor:
          selectedFiles.length >= TOTAL_CSVS ? "#FEB2B2" : "#B2F5EA",
        color: selectedFiles.length >= TOTAL_CSVS ? "#742A2A" : "#1A4E4E",
      },
    }),
  };

  const removeUploadFile = (index) => {
    setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleUpload = () => {
    if (uploadFiles.length > 0 && selectedFolder) {
      // For now, we'll only upload the first file
      uploadCSV(uploadFiles[0], selectedFolder);
      setUploadFiles([]);
    } else {
      toast.error("Please select a folder and at least one file to upload.");
    }
  };

  const handleCreateFolder = async () => {
    if (folderName?.trim()) {
      await createFolder();
    } else {
      toast.error("Please enter a folder name.");
    }
  };

  const handleLocalQuery = () => {
    if (localQuery?.trim()) {
      handleQuery(localQuery);
      setLocalQuery("");
    }
  };

  const isChatDisabled = !selectedFiles.length || !csvPaths.length;

  console.log("csvPaths", csvPaths);

  return (
    <div className="p-4 md:p-6 bg-gray-100 dark:bg-gray-900 min-h-screen font-sans">
      <div className="max-w-[1400px] mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">
          CSV Querying
        </h1>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select a folder
              </label>
              <div className="flex items-center space-x-2">
                <Select
                  styles={customSelectStyles}
                  options={folderSelectOptions}
                  onChange={(option) => handleFolderSelect(option.value)}
                  value={folderSelectOptions?.find(
                    (option) => option?.value === selectedFolder
                  )}
                  placeholder="Choose a folder"
                  isLoading={isFolderLoading}
                  className="react-select-container flex-grow"
                  classNamePrefix="react-select"
                />
                <button
                  onClick={() => fetchFolderOptions(companyFolder)}
                  className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                  title="Refresh folders"
                  disabled={isFolderLoading}
                >
                  {isFolderLoading ? (
                    <Loader size={18} color="white" />
                  ) : (
                    <RefreshCcw size={18} />
                  )}
                </button>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Create new folder
              </label>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  value={folderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                  placeholder="Enter folder name"
                  className="flex-grow p-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
                <button
                  onClick={handleCreateFolder}
                  disabled={isFolderLoading || !folderName?.trim()}
                  className={`p-2 ${
                    isFolderLoading || !folderName?.trim()
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-600"
                  } text-white rounded-md transition-colors`}
                  title="Create folder"
                >
                  <FolderPlus size={18} />
                </button>
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Select files (max {TOTAL_CSVS})
              </label>
              <Select
                styles={customSelectStyles}
                isMulti
                options={fileOptions?.map((file) => ({
                  value: file,
                  label: file,
                }))}
                value={selectedFiles?.map((file) => ({
                  value: file,
                  label: file,
                }))}
                onChange={handleFileSelect}
                placeholder="Choose files"
                isDisabled={!selectedFolder}
                isLoading={isFileLoading}
                className="react-select-container"
                classNamePrefix="react-select"
                noOptionsMessage={() => "No files available"}
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {selectedFiles.length}/{TOTAL_CSVS} files selected
              </p>
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Upload CSV
            </label>
            <div {...getRootProps()} className="mb-4">
              <input {...getInputProps()} />
              <div
                className={`p-6 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors ${
                  isDragActive
                    ? "border-blue-500 bg-blue-50 dark:bg-blue-900"
                    : "border-gray-300 dark:border-gray-700"
                }`}
              >
                <Upload size={24} className="mx-auto mb-2 text-gray-400" />
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {isDragActive
                    ? "Drop the CSV file here"
                    : "Drag & drop a CSV file here, or click to select"}
                </p>
              </div>
            </div>
            {uploadFiles.length > 0 && (
              <div className="mb-4">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Selected file{uploadFiles.length > 1 ? "s" : ""}:
                </h4>
                <ul className="space-y-2">
                  {uploadFiles.map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between bg-white dark:bg-gray-800 p-2 rounded-md"
                    >
                      <span className="text-sm text-gray-600 dark:text-gray-400 truncate">
                        {file.name}
                      </span>
                      <button
                        onClick={() => removeUploadFile(index)}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        <X size={18} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <button
              onClick={handleUpload}
              disabled={
                !uploadFiles.length || !selectedFolder || isUploadLoading
              }
              className={`w-full px-4 py-2 rounded-md transition-colors ${
                uploadFiles.length && selectedFolder && !isUploadLoading
                  ? "bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400"
              }`}
            >
              {isUploadLoading ? <Loader size={18} color="white" /> : "Upload"}
            </button>
          </div>

          {error && <MessageBox type="error" title="Error" message={error} />}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Chat Interface */}
          <div className="bg-white dark:bg-gray-800 rounded-lg rounded-tr-none rounded-br-none shadow-md overflow-hidden flex flex-col h-[700px]">
            <ChatBody messages={chatHistory} loading={isQueryLoading} />
            <div className="p-4 bg-white dark:bg-gray-800">
              <div
                className={`flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg ${
                  isChatDisabled ? "opacity-50" : ""
                }`}
              >
                <input
                  type="text"
                  value={localQuery}
                  onChange={(e) => setLocalQuery(e.target.value)}
                  placeholder={
                    isChatDisabled
                      ? "Select CSV files to start querying"
                      : "Ask about the CSV data..."
                  }
                  className="flex-grow p-3 font-poppins bg-transparent border-none focus:outline-none focus:ring-0 text-gray-800 dark:text-gray-200"
                  disabled={isChatDisabled}
                />
                <button
                  onClick={handleLocalQuery}
                  className={`p-3 ${
                    isChatDisabled
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-[#377FFF] hover:text-blue-600"
                  } focus:outline-none transition-all duration-300 ease-in-out`}
                  disabled={isQueryLoading || isChatDisabled}
                >
                  <Send size={20} />
                </button>
              </div>
              {isChatDisabled && (
                <div className="mt-2 p-3 items-center bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-md flex items-start">
                  <FileText
                    size={20}
                    className="text-gray-500 dark:text-gray-400 mr-2 flex-shrink-0"
                  />
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {selectedFiles.length === 0
                      ? "Select CSV files from the dropdown above to begin analysis."
                      : "Previewing selected files. This may take a moment..."}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* CSV Tables */}
          <div className="bg-white dark:bg-gray-800 rounded-lg rounded-tl-none rounded-bl-none   shadow-md overflow-hidden h-[700px]">
            <div className="h-full overflow-y-auto px-2 py-2">
            {isPreviewLoading ? (
                <SkeletonLoader />
              ) : Object.keys(csvData).length > 0 ? (
                Object.entries(csvData)
                  .slice(0, TOTAL_CSVS)
                  .map(([fileName, data], index) => (
                    <div
                      key={fileName}
                      className={`mb-6 ${
                        index > 0
                          ? "pt-6 border-t border-gray-200 dark:border-gray-700"
                          : ""
                      }`}
                    >
                      <CSVTable data={data} fileName={fileName} />
                    </div>
                  ))
              ) : (
                <CSVPreviewPlaceholder />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsvQuerying;
