import React, { useState } from "react";
import {
  Database,
  Play,
  Copy,
  Check,
  ChevronDown,
  ChevronUp,
  Clock,
  AlertCircle,
  Search,
  Code,
  Clipboard,
  CheckSquare,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const HistoryItem = ({ item, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.05 }}
      className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md transition-shadow duration-200"
    >
      <div
        className="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="bg-blue-100 dark:bg-blue-900 p-2 rounded-full">
              <Search size={18} className="text-blue-500 dark:text-blue-400" />
            </div>
            <p className="text-sm font-medium text-gray-700 dark:text-gray-200 truncate max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg">
              {item.query}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="text-xs text-gray-500 dark:text-gray-400 flex items-center">
              <Clock size={12} className="mr-1" />
              {item.timestamp.toLocaleString()}
            </p>
            {isExpanded ? (
              <ChevronUp
                size={18}
                className="text-gray-500 dark:text-gray-400"
              />
            ) : (
              <ChevronDown
                size={18}
                className="text-gray-500 dark:text-gray-400"
              />
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="px-4 pb-4"
          >
            <div className="mb-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-300 mb-2 flex items-center">
                <Search
                  size={16}
                  className="mr-2 text-blue-500 dark:text-blue-400"
                />
                Query:
              </p>
              <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-md relative group">
                <p className="text-sm text-gray-700 dark:text-gray-300">
                  {item.query}
                </p>
                <button
                  onClick={() => copyToClipboard(item.query)}
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                >
                  {copied ? (
                    <CheckSquare size={16} className="text-green-500" />
                  ) : (
                    <Clipboard
                      size={16}
                      className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    />
                  )}
                </button>
              </div>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-300 mb-2 flex items-center">
                <Code
                  size={16}
                  className="mr-2 text-green-500 dark:text-green-400"
                />
                Result:
              </p>
              <div className="bg-gray-100 dark:bg-gray-700 p-3 rounded-md relative group">
                <pre className="text-sm text-gray-700 dark:text-gray-300 overflow-x-auto">
                  {item.result}
                </pre>
                <button
                  onClick={() => copyToClipboard(item.result)}
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                >
                  {copied ? (
                    <CheckSquare size={16} className="text-green-500" />
                  ) : (
                    <Clipboard
                      size={16}
                      className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    />
                  )}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const NL2SQL = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [copied, setCopied] = useState(false);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      // Simulating API call
      await new Promise((resolve) => setTimeout(resolve, 1500));
      const newResult = `SELECT 
    c.name 
FROM 
    customers c
JOIN 
    orders o ON c.customer_id = o.customer_id
JOIN 
    order_details od ON o.order_id = od.order_id
JOIN 
    products p ON od.product_id = p.product_id
WHERE 
    o.order_date >= DATEADD(month, -1, GETDATE())
GROUP BY 
    c.name
HAVING 
    COUNT(DISTINCT p.product_id) > 3;
`;
      setResult(newResult);
      setHistory([
        { query, result: newResult, timestamp: new Date() },
        ...history,
      ]);
    } catch (err) {
      setError("An error occurred while generating SQL. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(result);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 space-y-8 font-poppins">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold mb-8 font-poppins text-gray-800 dark:text-white"
      >
        Natural Language to SQL
      </motion.h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700"
        >
          <div className="p-6 space-y-6">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="query"
                  className="block font-poppins text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Enter your query in natural language:
                </label>
                <div className="relative">
                  <textarea
                    id="query"
                    className="w-full p-4 font-poppins border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white transition duration-200 resize-none"
                    rows="4"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="e.g., Show me all users older than 30"
                  ></textarea>
                  <Database
                    className="absolute right-3 top-3 text-gray-400 dark:text-gray-500"
                    size={20}
                  />
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                disabled={isLoading}
                className="w-full bg-gradient-to-r font-poppins from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-200 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
              >
                {isLoading ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      ease: "linear",
                    }}
                  >
                    <Play size={20} className="mr-2" />
                  </motion.div>
                ) : (
                  <Play size={20} className="mr-2" />
                )}
                {isLoading ? "Generating..." : "Generate SQL"}
              </motion.button>
            </form>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700"
        >
          {result && (
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-poppins font-semibold text-gray-800 dark:text-white">
                  Generated SQL:
                </h2>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={copyToClipboard}
                  className="text-blue-600 font-poppins hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition duration-200 flex items-center"
                >
                  {copied ? (
                    <Check size={20} className="mr-2" />
                  ) : (
                    <Copy size={20} className="mr-2" />
                  )}
                  {copied ? "Copied!" : "Copy"}
                </motion.button>
              </div>
              <motion.pre
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-100 dark:bg-gray-900 p-4 rounded-lg text-sm overflow-x-auto shadow-inner text-gray-800 dark:text-gray-200"
              >
                {result}
              </motion.pre>
            </div>
          )}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="p-4 bg-red-100 font-poppins dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg mt-4 flex items-center"
            >
              <AlertCircle size={20} className="mr-2" />
              {error}
            </motion.div>
          )}
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="mt-8"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowHistory(!showHistory)}
          className="flex items-center font-poppins text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition duration-200 bg-gray-100 dark:bg-gray-800 px-4 py-2 rounded-full shadow-md"
        >
          {showHistory ? (
            <ChevronUp size={20} className="mr-2" />
          ) : (
            <ChevronDown size={20} className="mr-2" />
          )}
          {showHistory ? "Hide History" : "Show History"}
        </motion.button>
        <AnimatePresence>
          {showHistory && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="mt-4 bg-gray-50 dark:bg-gray-900 rounded-lg shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white flex items-center">
                  <Search
                    size={24}
                    className="mr-2 font-poppins text-blue-500 dark:text-blue-400"
                  />
                  Query History
                </h3>
                <div className="space-y-4 max-h-[calc(100vh-200px)] overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-400 dark:scrollbar-thumb-gray-600 scrollbar-track-gray-200 dark:scrollbar-track-gray-800">
                  {history.map((item, index) => (
                    <HistoryItem key={index} item={item} index={index} />
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default NL2SQL;
