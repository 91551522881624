import React, { useState, useEffect, useRef } from "react";
import {
  Clipboard,
  Mic,
  StopCircle,
  RefreshCw,
  Check,
  AlertCircle,
  FileText,
  Save,
  User,
  ChevronDown,
  ChevronUp,
  Clock,
  Activity,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../components/ThemeProvider";

const MedicalScribing = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [recordingTime, setRecordingTime] = useState(0);
  const [showPatientInfo, setShowPatientInfo] = useState(false);
  const [voiceLevel, setVoiceLevel] = useState(0);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const microphoneRef = useRef(null);

  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      setRecordingTime(0);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  useEffect(() => {
    if (isRecording) {
      startVoiceLevelMonitoring();
    } else {
      stopVoiceLevelMonitoring();
    }
  }, [isRecording]);

  const startVoiceLevelMonitoring = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      microphoneRef.current =
        audioContextRef.current.createMediaStreamSource(stream);
      microphoneRef.current.connect(analyserRef.current);

      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      const updateVoiceLevel = () => {
        analyserRef.current.getByteFrequencyData(dataArray);
        const average =
          dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
        setVoiceLevel(average / 128);
        if (isRecording) {
          requestAnimationFrame(updateVoiceLevel);
        }
      };
      updateVoiceLevel();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopVoiceLevelMonitoring = () => {
    if (microphoneRef.current) {
      microphoneRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setVoiceLevel(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording);
    if (!isRecording) {
      const dummyTranscript =
        "Patient presents with severe headache lasting for 3 days. Reports sensitivity to light and sound. No fever or nausea. Medical history includes migraines. Current medications: none. Recommending MRI and prescribing sumatriptan for acute relief.";
      setTimeout(() => {
        setTranscript(dummyTranscript);
        setIsRecording(false);
      }, 10000);
    }
  };

  const generateSummary = async () => {
    setIsLoading(true);
    setError("");
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const dummySummary = `
Patient Complaint: Severe headache for 3 days
Symptoms: 
- Light and sound sensitivity
- No fever or nausea
Medical History: Migraines
Current Medications: None
Recommendations:
1. MRI scan
2. Prescribed sumatriptan for acute relief
Follow-up: Schedule in 2 weeks to review MRI results and medication efficacy.
      `;
      setSummary(dummySummary.trim());
    } catch (err) {
      setError(
        "An error occurred while generating the summary. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const saveOrExportSummary = () => {
    // Implement save or export functionality
    console.log("Saving or exporting summary...");
  };

  return (
    <div className={`mx-auto px-4 py-8 space-y-8 font-poppins min-h-screen ${
      isDarkMode
        ? "bg-gradient-to-br from-gray-900 to-blue-900"
        : "bg-gradient-to-br from-blue-50 to-purple-50"
    }`}>
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl font-bold mb-8 text-gray-800 dark:text-white flex items-center"
      >
        <Clipboard className="mr-4 text-blue-500" size={36} />
        Medical Scribing
      </motion.h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`rounded-xl shadow-lg overflow-hidden border transition-all duration-300 ${
            isDarkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-blue-500/20"
              : "bg-white border-gray-200 hover:shadow-purple-500/20"
          }`}
        >
          <div className="p-6 space-y-6">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 flex items-center">
                <Mic size={24} className="mr-2 text-red-500" />
                Voice Recording
              </h2>
              <div className="flex items-center justify-between">
                <div className="relative">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={toggleRecording}
                    className={`relative w-20 h-20 rounded-full flex items-center justify-center shadow-lg hover:shadow-xl transition-all duration-300 ${
                      isRecording
                        ? "bg-red-500 hover:bg-red-600"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                  >
                    <motion.div
                      animate={{ scale: isRecording ? [1, 1.2, 1] : 1 }}
                      transition={{ repeat: Infinity, duration: 1 }}
                    >
                      {isRecording ? (
                        <StopCircle size={40} className="text-white" />
                      ) : (
                        <Mic size={40} className="text-white" />
                      )}
                    </motion.div>
                  </motion.button>
                  <AnimatePresence>
                    {isRecording && (
                      <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        className={`absolute -top-2 -right-2 rounded-full px-3 py-1 text-sm font-semibold shadow-md ${
                          isDarkMode
                            ? "bg-gray-700 text-white"
                            : "bg-white text-gray-800"
                        }`}
                      >
                        <Clock size={14} className="inline mr-1" />
                        {formatTime(recordingTime)}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
                    {isRecording ? "Recording..." : "Ready to record"}
                  </span>
                  <motion.div
                    className={`w-32 h-4 rounded-full overflow-hidden ${
                      isDarkMode ? "bg-gray-700" : "bg-gray-200"
                    }`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isRecording ? 1 : 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.div
                      className="h-full bg-gradient-to-r from-green-400 to-blue-500"
                      initial={{ width: 0 }}
                      animate={{ width: `${voiceLevel * 100}%` }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 30,
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300 mb-2 flex items-center">
                <FileText size={20} className="mr-2 text-blue-500" />
                Transcript:
              </h3>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className={`p-4 rounded-lg min-h-[200px] max-h-[300px] overflow-y-auto custom-scrollbar ${
                  isDarkMode ? "bg-gray-700" : "bg-gray-100"
                }`}
              >
                <p className="text-sm text-gray-800 dark:text-gray-200">
                  {transcript || "Transcript will appear here..."}
                </p>
              </motion.div>
            </div>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={generateSummary}
              disabled={isLoading || !transcript}
              className="w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
            >
              {isLoading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                >
                  <RefreshCw size={20} className="mr-2" />
                </motion.div>
              ) : (
                <Activity size={20} className="mr-2" />
              )}
              {isLoading ? "Generating Summary..." : "Generate Summary"}
            </motion.button>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className={`rounded-xl shadow-lg overflow-hidden border transition-all duration-300 ${
            isDarkMode
              ? "bg-gray-800 border-gray-700 hover:shadow-blue-500/20"
              : "bg-white border-gray-200 hover:shadow-purple-500/20"
          }`}
        >
          <div className="p-6 space-y-6">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-white flex items-center">
                <FileText size={24} className="mr-2 text-green-500" />
                Medical Summary
              </h2>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={saveOrExportSummary}
                disabled={!summary}
                className="bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
              >
                <Save size={20} className="mr-2" />
                Save
              </motion.button>
            </div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="mb-4"
            >
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowPatientInfo(!showPatientInfo)}
                className="flex items-center text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                <motion.div
                  animate={{ rotate: showPatientInfo ? 180 : 0 }}
                  transition={{ type: "spring", stiffness: 200, damping: 20 }}
                >
                  <ChevronDown size={20} />
                </motion.div>
                <span className="ml-2">Patient Information</span>
              </motion.button>
              <AnimatePresence>
                {showPatientInfo && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 40,
                      opacity: { duration: 0.2 },
                    }}
                    className={`mt-4 p-4 rounded-lg shadow-inner overflow-hidden ${
                      isDarkMode ? "bg-gray-700" : "bg-gray-50"
                    }`}
                  >
                    <motion.div
                      initial={{ y: -20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -20, opacity: 0 }}
                      transition={{ delay: 0.1 }}
                    >
                      <div className="flex items-center mb-2">
                        <User
                          size={16}
                          className="mr-2 text-gray-500 dark:text-gray-400"
                        />
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          John Doe, 45 years old
                        </span>
                      </div>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Medical History: Hypertension, Type 2 Diabetes
                      </p>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>

            {isLoading ? (
              <div className="space-y-4">
                {[...Array(4)].map((_, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`h-4 rounded animate-pulse ${
                      isDarkMode ? "bg-gray-700" : "bg-gray-200"
                    }`}
                    style={{ width: `${100 - index * 10}%` }}
                  ></motion.div>
                ))}
              </div>
            ) : summary ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className={`p-4 rounded-lg max-h-[400px] overflow-y-auto custom-scrollbar ${
                  isDarkMode ? "bg-gray-700" : "bg-gray-100"
                }`}
              >
                <pre className="text-sm text-gray-800 dark:text-gray-200 whitespace-pre-wrap">
                  {summary}
                </pre>
              </motion.div>
            ) : (
              <p className="text-gray-500 dark:text-gray-400 text-center py-8">
                Generated summary will appear here...
              </p>
            )}
            {error && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className={`p-4 rounded-lg flex items-center ${
                  isDarkMode
                    ? "bg-red-900 text-red-200"
                    : "bg-red-100 text-red-700"
                }`}
              >
                <AlertCircle size={20} className="mr-2" />
                {error}
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default MedicalScribing;