import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Home,
  Search,
  MessageCircle,
  LogOut,
  ChevronLeft,
  ChevronRight,
  Menu,
  BarChart2,
  Sun,
  Moon,
  Database,
  FileText,
  Filter,
  GitMerge,
  Image,
  Shield,
  Clipboard,
  HelpCircle,
  RefreshCw,
  ChevronDown,
  File,
  User,
  Activity,
  DollarSign,
  ThumbsUp,
  Edit,
  Briefcase,
  Grid,
  Mail,
  Tool,
  Users,
  Delete,
  UserX,
  ChevronUp,
  Paperclip,
} from "react-feather";
import useAuthStore from "../stores/authStore";
import { motion, AnimatePresence } from "framer-motion";
import { ThemeContext } from "../components/ThemeProvider";
import axios from "axios";
import Avahi from "../assets/icons/Avahi";
import ToggleLeftPanel from "../assets/icons/ToggleLeftPanel";

const Header = ({ isOpen, onToggle, isSmallScreen }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center">
         <div className="flex items-center justify-between p-4">
      <div className="flex  w-full items-center space-x-3">
        <div
          className={` ${
            isSmallScreen ? "w-full justify-center" : "w-auto"
          }  flex items-center`}
        >
          <div
            className={`${!isOpen ? "py-2 p-1 px-2" : "p-2 py-2 px-2"} ${
              isSmallScreen ? "w-auto" : "w-auto"
            } dark:bg-purple-500 bg-[#7432BB] rounded-lg `}
          >
            <Avahi width={!isOpen ? 14 : 26} height={!isOpen ? 14 : 22} />
          </div>
        </div>

        {!isSmallScreen && isOpen && (
          <h1 className="text-base text-start font-semibold">
            Avahi AI Platform
          </h1>
        )}
      </div>
      {!isSmallScreen && (
        <div className="flex ml-2 flex-col items-center justify-center">
          <button
            onClick={onToggle}
            className="focus:outline-none text-gray-500 hover:text-blue-500 transition-colors"
          >
            <ToggleLeftPanel />
          </button>
        </div>
      )}

    </div>
    <ThemeToggle isOpen={isOpen} isSmallScreen={isSmallScreen} />

    </div>
 
  );
};

const MenuItem = ({ icon: Icon, text, path, isOpen, isSmallScreen }) => {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `flex items-center px-4 py-3 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-200 ${
          isActive ? "bg-[#F4F4FB] dark:bg-purple-900 border-l-2 border-[#7432BB] dark:border-transparent text-purple-600 dark:text-purple-200 font-semibold" : ""
        } ${isOpen && !isSmallScreen ? "justify-start" : "justify-center"}`
      }
    >
      <Icon size={20} className="flex-shrink-0" />
      {isOpen && !isSmallScreen && (
        <span className="ml-3 truncate">{text}</span>
      )}
    </NavLink>
  );
};

const ThemeToggle = ({ isOpen, isSmallScreen }) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <div className={`px-4 py-2 ${isSmallScreen || !isOpen ? 'flex w-full items-center justify-center' : ''}`}>
      <div 
        className={`relative flex items-center bg-[#EFEFEF] shadow-inner dark:bg-[#221638] rounded-full cursor-pointer ${
          isSmallScreen || !isOpen ? 'w-12 h-6' : 'w-24 h-8'
        }`}
        onClick={toggleDarkMode}
      >
        <motion.div
          className={`absolute flex items-center justify-center rounded-full bg-[#FFFFFF] dark:bg-gradient-to-b dark:from-[#6C36B4] dark:to-[#221638] ${
            isSmallScreen || !isOpen ? 'w-5 h-5' : 'w-7 h-7'
          }`}
          animate={{ x: isDarkMode ? (isSmallScreen || !isOpen ? 24 : 66) : 2 }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          {isDarkMode ? (
            <Moon size={isSmallScreen || !isOpen ? 12 : 16} className="text-white" />
          ) : (
            <Sun size={isSmallScreen || !isOpen ? 12 : 16} className="text-gray-800" />
          )}
        </motion.div>
      </div>
    </div>
  );
};

const LeftPanel = ({ isOpen, onToggle }) => {
  const { isLoggedIn, toggleLogoutPopup, toggleDeleteAccountPopup } =
    useAuthStore();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Enterprise");
  const [expandedSections, setExpandedSections] = useState({
    Tools: true,
    Objects: true,
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSection = (section) => {
    if (!isSmallScreen && isOpen) {
      setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
    }
  };

  const categories = {
    Enterprise: [
      { icon: Grid, text: "Dashboard", path: "/dashboard" },
      { icon: Mail, text: "Inbox", path: "/inbox" },
      { icon: MessageCircle, text: "Assistant", path: "/chat" },
      { icon: MessageCircle, text: "Assistant V2", path: "/assistant-v2" },
      {
        section: "Tools",
        icon: Tool,
        items: [
          { icon: Database, text: "NL2SQL", path: "/nl2sql" },
          { icon: Filter, text: "Data Extractor", path: "/data-extractor" },
          {
            icon: GitMerge,
            text: "Entity Resolution",
            path: "/entity-resolution",
          },
          { icon: File, text: "PDF Viewer", path: "/pdf-viewer" },
          { icon: Image, text: "Image Generation", path: "/image-generation" },
          { icon: Shield, text: "Data Masking", path: "/data-masking" },
          {
            icon: Clipboard,
            text: "Medical Scribing",
            path: "/medical-scribing",
          },
          {
            icon: RefreshCw,
            text: "OpenAI to Bedrock",
            path: "/openai-to-bedrock",
          },
          { icon: FileText, text: "CSV Querying", path: "/csv-querying" },
        ],
      },
      {
        section: "Objects",
        icon: Users,
        items: [
          { icon: File, text: "Files", path: "/files" },
          { icon: File, text: "Documents", path: "/documents" },
          { icon: Image, text: "Images", path: "/images" },
        ],
      },
    ],
    "Public Sector": [
      { icon: Grid, text: "Dashboard", path: "/dashboard" },
      { icon: Mail, text: "Inbox", path: "/inbox" },
      { icon: MessageCircle, text: "Assistant", path: "/chat" },
      {
        section: "Tools",
        icon: Tool,
        items: [
          { icon: MessageCircle, text: "Assistant", path: "/chat" },
          { icon: Image, text: "Image Generation", path: "/image-generation" },
          {
            icon: FileText,
            text: "Meeting Summarizer",
            path: "/meeting-summarizer",
          },
          { icon: File, text: "PDF Viewer", path: "/pdf-viewer" },
          { icon: DollarSign, text: "Tax Processing", path: "/tax-processing" },
          {
            icon: FileText,
            text: "Contract Management",
            path: "/contract-management",
          },
          { icon: Search, text: "Smart Search", path: "/smart-search" },
        ],
      },
      {
        section: "Objects",
        icon: Users,
        items: [
          { icon: File, text: "Files", path: "/files" },
          { icon: File, text: "Documents", path: "/documents" },
          { icon: Image, text: "Images", path: "/images" },
        ],
      },
    ],
    Healthcare: [
      { icon: Grid, text: "Dashboard", path: "/dashboard" },
      { icon: Mail, text: "Inbox", path: "/inbox" },
      { icon: MessageCircle, text: "Assistant", path: "/chat" },
      {
        section: "Tools",
        icon: Tool,
        items: [
          { icon: MessageCircle, text: "Assistant", path: "/chat" },
          { icon: Shield, text: "Data Masking", path: "/data-masking" },
          {
            icon: RefreshCw,
            text: "OpenAI to Bedrock",
            path: "/openai-to-bedrock",
          },
          { icon: File, text: "PDF Viewer", path: "/pdf-viewer" },
          { icon: Activity, text: "ClaimsAI", path: "/claims-ai" },
          {
            icon: ThumbsUp,
            text: "Prior Authorization",
            path: "/prior-authorization",
          },
          { icon: DollarSign, text: "Billing AI", path: "/login" },
          { icon: Edit, text: "Coding AI", path: "/coding-ai" },
        ],
      },
      {
        section: "Objects",
        icon: Users,
        items: [
          { icon: File, text: "Files", path: "/files" },
          { icon: File, text: "Documents", path: "/documents" },
          { icon: Image, text: "Images", path: "/images" },
        ],
      },
    ],
    Finance: [
      { icon: Grid, text: "Dashboard", path: "/dashboard" },
      { icon: Mail, text: "Inbox", path: "/inbox" },
      { icon: MessageCircle, text: "Assistant", path: "/chat" },
      {
        section: "Tools",
        icon: Tool,
        items: [
          { icon: Shield, text: "Data Masking", path: "/data-masking" },
          {
            icon: RefreshCw,
            text: "OpenAI to Bedrock",
            path: "/openai-to-bedrock",
          },
          { icon: File, text: "PDF Viewer", path: "/pdf-viewer" },
          {
            icon: Briefcase,
            text: "Settlement Processing",
            path: "/settlement-processing",
          },
          {
            icon: MessageCircle,
            text: "Lending Copilot",
            path: "/lending-copilot",
          },
        ],
      },
      {
        section: "Objects",
        icon: Users,
        items: [
          { icon: File, text: "Files", path: "/files" },
          { icon: File, text: "Documents", path: "/documents" },
          { icon: Image, text: "Images", path: "/images" },
        ],
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderMenuItem = (item) => (
    <MenuItem
      key={item.text}
      icon={item.icon}
      text={item.text}
      path={item.path}
      isOpen={isOpen}
      isSmallScreen={isSmallScreen}
    />
  );

  const renderSection = (section) => (
    <div key={section.section}>
      <button
        onClick={() => toggleSection(section.section)}
        className={`flex items-center w-full px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${
          isOpen && !isSmallScreen ? "justify-start" : "justify-center"
        }`}
      >
        <section.icon size={20} />
        {isOpen && !isSmallScreen && (
          <>
            <span className="ml-3">{section.section}</span>
            <ChevronRight
              size={16}
              className={`ml-auto transition-transform duration-200 ${
                expandedSections[section.section] ? "rotate-90" : ""
              }`}
            />
          </>
        )}
      </button>
      {expandedSections[section.section] && isOpen && !isSmallScreen && (
        <div className="pl-4">{section.items.map(renderMenuItem)}</div>
      )}
    </div>
  );

  const CategoryDropdown = ({ selectedCategory, setSelectedCategory, categories }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const getCategoryIcon = (category) => {
      switch (category) {
        case 'Enterprise': return <Paperclip size={20} />;
        case 'Public Sector': return <Users size={20} />;
        case 'Healthcare': return <Activity size={20} />;
        case 'Finance': return <DollarSign size={20} />;
        default: return <Paperclip size={20} />;
      }
    };
  
    const categoryList = Object.keys(categories);
    const currentIndex = categoryList.indexOf(selectedCategory);
  
    const navigateCategory = (direction) => {
      const newIndex = (currentIndex + direction + categoryList.length) % categoryList.length;
      setSelectedCategory(categoryList[newIndex]);
    };
  
    return (
      <div className="relative px-4 py-2">
        <div 
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center">
            <div className="w-10 h-10 dark:text-black bg-purple-100 rounded-lg flex items-center justify-center mr-3">
              {getCategoryIcon(selectedCategory)}
            </div>
            <span className="text-lg font-medium">{selectedCategory}</span>
          </div>
          <div className="flex flex-col">
            <button
              onClick={(e) => { e.stopPropagation(); navigateCategory(-1); }}
              className="text-gray-500 hover:text-gray-700"
            >
              <ChevronUp size={16} />
            </button>
            <button
              onClick={(e) => { e.stopPropagation(); navigateCategory(1); }}
              className="text-gray-500 hover:text-gray-700"
            >
              <ChevronDown size={16} />
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="absolute z-10 w-full mt-2 bg-white rounded-lg shadow-lg">
            {categoryList.map((category) => (
              <div
                key={category}
                className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => { setSelectedCategory(category); setIsOpen(false); }}
              >
                <div className="w-8 h-8 dark:text-black text-black bg-purple-100 rounded-lg flex items-center justify-center mr-3">
                  {getCategoryIcon(category)}
                </div>
                <span className="dark:text-black text-black">{category}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const bottomMenuItemClass = `flex items-center w-full px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-all duration-200 rounded-md ${
    isOpen && !isSmallScreen ? 'justify-start' : 'justify-center'
  }`;


  return (
    <motion.aside
      initial={false}
      animate={{
        width: isSmallScreen || !isOpen ? "100px" : "256px",
      }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="bg-[#F4F4FB] dark:bg-gray-900 font-poppins flex-shrink-0 h-full shadow-lg flex flex-col"
    >
      <Header
        isOpen={isOpen}
        onToggle={onToggle}
        isSmallScreen={isSmallScreen}
      />

      {isOpen && !isSmallScreen && (
        <>
          <CategoryDropdown
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
          />
        </>
      )}
      <div className=" my-4 border-b-[1.5px] border-dashed border-[#252525] dark:border-gray-700"></div>

      <nav className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
        {categories[selectedCategory].map((item) =>
          item.section ? renderSection(item) : renderMenuItem(item)
        )}
      </nav>

      <div className="p-4 border-t border-gray-200 dark:border-gray-700 space-y-2">
        <NavLink
          to="/help"
          className={({ isActive }) =>
            `${bottomMenuItemClass} ${
              isActive ? "bg-purple-100 dark:bg-purple-900 text-purple-600 dark:text-purple-200 font-semibold" : ""
            }`
          }
        >
          <HelpCircle size={20} className="flex-shrink-0" />
          {isOpen && !isSmallScreen && (
            <span className="ml-3 truncate">Help</span>
          )}
        </NavLink>
        <button
          onClick={toggleDeleteAccountPopup}
          className={bottomMenuItemClass}
        >
          <UserX size={20} className="flex-shrink-0" />
          {isOpen && !isSmallScreen && (
            <span className="ml-3 truncate">Delete Account</span>
          )}
        </button>
        {isLoggedIn && (
          <button
            onClick={toggleLogoutPopup}
            className={`${bottomMenuItemClass} ${
              isOpen && !isSmallScreen ? 'bg-transparent dark:bg-transparent' : ''
            }`}
          >
            <LogOut size={20} className="flex-shrink-0" />
            {isOpen && !isSmallScreen && (
              <span className="ml-3 truncate">Logout</span>
            )}
          </button>
        )}
      </div>
    </motion.aside>
  );
};

export default LeftPanel;
