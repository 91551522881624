import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { motion } from 'framer-motion';
import { Upload, ZoomIn, ZoomOut, ChevronLeft, ChevronRight, Maximize, Printer, MoreHorizontal, Send } from 'react-feather';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Header = ({ currentPage, totalPages, scale, onPageChange, onZoomChange }) => (
  <div className="flex items-center justify-between p-2 bg-white dark:bg-gray-800 text-gray-800 dark:text-white sticky top-0 z-10 shadow-sm">
    <div className="flex items-center space-x-2">
      <button onClick={() => onPageChange(-1)} className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors" disabled={currentPage === 1}>
        <ChevronLeft size={20} />
      </button>
      <div className="flex items-center bg-gray-200 dark:bg-gray-700 rounded px-2 py-1">
        <input 
          type="number" 
          value={currentPage} 
          onChange={(e) => {
            const page = Math.max(1, Math.min(parseInt(e.target.value) || 1, totalPages));
            onPageChange(page - currentPage);
          }}
          className="w-12 bg-transparent text-center focus:outline-none"
        />
        <span className="text-gray-600 dark:text-gray-400">/ {totalPages}</span>
      </div>
      <button onClick={() => onPageChange(1)} className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors" disabled={currentPage === totalPages}>
        <ChevronRight size={20} />
      </button>
    </div>
    <div className="flex items-center space-x-2">
      <button onClick={() => onZoomChange(-0.1)} className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <ZoomOut size={20} />
      </button>
      <div className="bg-gray-200 dark:bg-gray-700 rounded px-2 py-1">
        <span className="text-sm">{Math.round(scale * 100)}%</span>
      </div>
      <button onClick={() => onZoomChange(0.1)} className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <ZoomIn size={20} />
      </button>
      <button className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <Maximize size={20} />
      </button>
      <button className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <Printer size={20} />
      </button>
      <button className="p-1 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
        <MoreHorizontal size={20} />
      </button>
    </div>
  </div>
);

const PDFViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfFile, setPdfFile] = useState(null);
  const [scale, setScale] = useState(1.1);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const pdfContainerRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setPdfFile(URL.createObjectURL(file));
  };

  const handleZoom = (delta) => {
    setScale(prevScale => Math.max(0.5, Math.min(prevScale + delta, 2)));
  };

  const handlePageChange = useCallback((delta) => {
    setCurrentPage(prevPage => {
      const newPage = Math.max(1, Math.min(prevPage + delta, numPages));
      if (pdfContainerRef.current) {
        const pageElement = pdfContainerRef.current.querySelector(`[data-page-number="${newPage}"]`);
        if (pageElement) {
          pageElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
      return newPage;
    });
  }, [numPages]);

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages(prev => [...prev, { text: input, sender: 'user' }]);
      setInput('');
      setTimeout(() => {
        setMessages(prev => [...prev, { text: 'This is a simulated AI response.', sender: 'ai' }]);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (pdfContainerRef.current) {
        const pages = pdfContainerRef.current.querySelectorAll('.react-pdf__Page');
        const containerTop = pdfContainerRef.current.scrollTop;
        const containerHeight = pdfContainerRef.current.clientHeight;
        let closestPage = 1;
        let smallestDistance = Infinity;

        pages.forEach((page) => {
          const rect = page.getBoundingClientRect();
          const distance = Math.abs(rect.top + rect.height / 2 - containerHeight / 2);
          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestPage = parseInt(page.getAttribute('data-page-number'));
          }
        });

        setCurrentPage(closestPage);
      }
    };

    const container = pdfContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-white">
      <div className="lg:w-1/2 h-1/2 lg:h-full flex flex-col">
        <Header 
          currentPage={currentPage} 
          totalPages={numPages} 
          scale={scale} 
          onPageChange={handlePageChange} 
          onZoomChange={handleZoom} 
        />
        <div ref={pdfContainerRef} className="flex-grow overflow-auto bg-white dark:bg-gray-800">
          {pdfFile ? (
            <Document 
              file={pdfFile} 
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  scale={scale}
                  className="mb-4"
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          ) : (
            <div className="flex items-center justify-center h-full">
              <label htmlFor="pdf-upload" className="cursor-pointer flex items-center space-x-2 bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white px-4 py-2 rounded transition-colors">
                <Upload size={20} />
                <span>Upload PDF</span>
              </label>
              <input id="pdf-upload" type="file" accept="application/pdf" onChange={handleFileUpload} className="hidden" />
            </div>
          )}
        </div>
      </div>
      
      <div className="lg:w-1/2 h-1/2 lg:h-full bg-gray-200 dark:bg-gray-800 flex flex-col">
        <div className="flex-grow overflow-auto p-4">
          {messages.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
            >
              <div className={`inline-block p-3 rounded-lg ${
                message.sender === 'user' 
                  ? 'bg-blue-500 dark:bg-blue-600 text-white'
                  : 'bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white'
              }`}>
                <p className="text-sm font-semibold mb-1">{message.sender === 'user' ? 'You' : 'AI Assistant'}</p>
                <p>{message.text}</p>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="p-4 bg-gray-100 dark:bg-gray-900">
          <div className="flex items-center bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-grow p-3 bg-transparent focus:outline-none"
              placeholder="Ask about the PDF..."
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white p-3 transition duration-200"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFViewer;