import React from "react";

const Avahi = ({ width = 26, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_16_810)">
      <path
        d="M18.3435 15.6272C18.3135 15.5716 18.2955 15.5363 18.2767 15.5025C15.9541 11.4798 13.6315 7.45705 11.3073 3.43506C11.2683 3.36823 11.2698 3.32167 11.3096 3.25484C11.8157 2.40178 12.3188 1.54647 12.8219 0.691911C12.876 0.600297 12.9301 0.508684 12.9947 0.399048C16.4963 6.33591 19.9896 12.2593 23.4897 18.1931H10.428C10.4483 18.1548 10.464 18.124 10.4813 18.0932C10.9416 17.3003 11.4034 16.508 11.8615 15.7143C11.9006 15.6467 11.9441 15.6242 12.0207 15.6242C14.0798 15.6264 16.1381 15.6257 18.1971 15.6257C18.2392 15.6257 18.2812 15.6257 18.3435 15.6257V15.6272Z"
        fill="white"
      />
      <path
        d="M10.9777 10.436C10.9995 10.4713 11.016 10.496 11.0303 10.5216C11.5432 11.4077 12.0553 12.2938 12.5712 13.1784C12.6133 13.2505 12.6148 13.3023 12.572 13.3751C11.5597 15.1195 10.5505 16.8654 9.54045 18.6114C9.51492 18.6549 9.49314 18.7 9.4601 18.7615C9.52468 18.7615 9.56974 18.7615 9.61404 18.7615C14.3066 18.7615 18.9984 18.7615 23.691 18.7593C23.7901 18.7593 23.8419 18.7901 23.8915 18.8742C24.4044 19.7453 24.9218 20.6133 25.4377 21.4822C25.4572 21.5152 25.4752 21.549 25.5 21.5926H4.5415C6.68766 17.8717 8.83007 14.1591 10.9785 10.436H10.9777Z"
        fill="white"
      />
      <path
        d="M10.9319 3.90741C13.0773 7.62452 15.2198 11.3364 17.3719 15.0632C17.3246 15.0632 17.2953 15.0632 17.266 15.0632C16.3116 15.0632 15.3572 15.0625 14.4027 15.0647C14.3291 15.0647 14.2893 15.0415 14.2526 14.9776C13.1877 13.1258 12.1207 11.2755 11.0536 9.42525C11.0341 9.39146 11.013 9.35842 10.9845 9.31111C10.956 9.35692 10.9327 9.39071 10.9117 9.426C8.59203 13.4472 6.27316 17.4677 3.95579 21.4897C3.90848 21.5723 3.85666 21.6001 3.7628 21.6001C2.71375 21.5971 1.66469 21.5978 0.614892 21.5978H0.5C3.98207 15.6933 7.45512 9.80447 10.9319 3.90741Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_16_810">
        <rect
          width="25"
          height="21.2003"
          fill="white"
          transform="translate(0.5 0.399841)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Avahi;