import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Image, Sliders, Zap, Download, RefreshCw, Save, List, Grid, ChevronDown } from 'react-feather';

const ImageGeneration = () => {
  const [prompt, setPrompt] = useState('');
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [imageSize, setImageSize] = useState('1024x1024');
  const [style, setStyle] = useState('Realistic');

  const handleGenerate = () => {
    setIsGenerating(true);
    // Simulating image generation
    setTimeout(() => {
      setGeneratedImages([...Array(4)].map(() => 'https://picsum.photos/300/300'));
      setIsGenerating(false);
    }, 2000);
  };

  const MotionSelect = ({ value, onChange, options, label }) => (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
        {label}
      </label>
      <motion.select
        value={value}
        onChange={onChange}
        className="w-full p-2 pr-8 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-white appearance-none cursor-pointer focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
        whileTap={{ scale: 0.98 }}
      >
        {options.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </motion.select>
      <ChevronDown size={20} className="absolute right-2 top-1/2 transform translate-y-1/2 text-gray-500 pointer-events-none" />
    </div>
  );

  return (
    <div className="min-h-screen font-poppins bg-gradient-to-br from-gray-50 to-blue-50 dark:from-gray-900 dark:to-blue-900 p-8">
      <div className="max-w-6xl mx-auto">
        <motion.header 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8"
        >
          <h1 className="text-4xl font-bold text-gray-800 dark:text-white flex items-center">
            <Image className="mr-4 text-blue-500" size={36} />
            AI Image Generator
          </h1>
        </motion.header>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden mb-8 transition-shadow duration-300 hover:shadow-xl"
        >
          <div className="p-6">
            <div className="flex flex-wrap gap-4 mb-6">
              {[
                { icon: Zap, label: "Generate", action: handleGenerate, primary: true },
                { icon: Save, label: "Save Prompt", action: () => {} },
                { icon: Download, label: "Download All", action: () => {} },
                { icon: RefreshCw, label: "Clear", action: () => setPrompt('') },
              ].map(({ icon: Icon, label, action, primary }) => (
                <motion.button
                  key={label}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={action}
                  className={`flex items-center px-4 py-2 rounded-md transition-all duration-200 ${
                    primary 
                      ? 'bg-blue-500 text-white hover:bg-blue-600 shadow-lg hover:shadow-xl' 
                      : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600'
                  }`}
                >
                  <Icon size={20} className="mr-2" />
                  {label}
                </motion.button>
              ))}
            </div>

            <div className="relative">
              <textarea
                rows={4}
                className="w-full p-4 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white transition duration-200 resize-none"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Describe the image you want to generate..."
              />
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="absolute right-3 bottom-3 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                <Sliders size={20} />
              </motion.button>
            </div>

            <AnimatePresence>
              {showAdvanced && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="overflow-hidden mt-4"
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <MotionSelect
                      value={imageSize}
                      onChange={(e) => setImageSize(e.target.value)}
                      options={['512x512', '1024x1024', '1536x1536']}
                      label="Image Size"
                    />
                    <MotionSelect
                      value={style}
                      onChange={(e) => setStyle(e.target.value)}
                      options={['Realistic', 'Abstract', 'Cartoon']}
                      label="Style"
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden transition-shadow duration-300 hover:shadow-xl"
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">Generated Images</h2>
              <div className="flex space-x-2 bg-gray-200 dark:bg-gray-700 rounded-md p-1">
                {['grid', 'list'].map((mode) => (
                  <motion.button
                    key={mode}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setViewMode(mode)}
                    className={`p-2 rounded ${viewMode === mode ? 'bg-blue-500 text-white' : 'text-gray-600 dark:text-gray-300'}`}
                  >
                    {mode === 'grid' ? <Grid size={20} /> : <List size={20} />}
                  </motion.button>
                ))}
              </div>
            </div>

            {isGenerating ? (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex justify-center items-center h-64"
              >
                <div className="relative w-20 h-20">
                  <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-ping"></div>
                  <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-500 rounded-full animate-pulse"></div>
                </div>
              </motion.div>
            ) : (
              <motion.div 
                layout
                className={`grid ${viewMode === 'grid' ? 'grid-cols-2 md:grid-cols-4' : 'grid-cols-1'} gap-4`}
              >
                <AnimatePresence>
                  {generatedImages.map((src, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="relative group overflow-hidden rounded-lg"
                    >
                      <img src={src} alt={`Generated ${index + 1}`} className="w-full h-auto transition-transform duration-300 group-hover:scale-110" />
                      <motion.div 
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                      >
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          className="p-2 bg-white text-gray-800 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        >
                          <Download size={20} />
                        </motion.button>
                      </motion.div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ImageGeneration;